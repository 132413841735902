.navbar-divider {
  border-right: 2px solid rgba(255, 255, 255, 0.5);
}

.nav-institute {
  background-color: rgb(33, 37, 41);
}
.nav-institute > .nav-link {
  color: rgba(255, 255, 255, 0.5);
}
.nav-institute > .nav-link:hover {
  color: rgba(255, 255, 255, 0.75);
}
.nav-institute > .active {
  color: white;
}
.nav-institute > .active:hover {
  color: white;
}

.message-parent-box {
  padding-left: 0px;
}

.noPaddingRight {
  padding-right: 0px;
}

.noPaddingLeft {
  padding-left: 0px;
}

.searchButton {
  margin-top: 32px;
}
